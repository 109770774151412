import { graphql, PageProps } from 'gatsby';
import React from 'react';
import MDX from '../cms/MDX';
import Section from '../components/formatting/Section';
import SectionDescription from '../components/formatting/SectionDescription';
import WithHeroLayout from '../layouts/WithHeroLayout';

interface Props extends PageProps {
  data: {
    file: {
      childMdx: {
        frontmatter: {
          link: string;
          title: string;
          content: string;
          pub_date: string;
          excerpt?: string;
          hero_image: string;
          html_embed_code?: string;
        };
      };
    };
  };
}

const PageTemplate: React.FC<Props> = function (props) {
  const { data, location } = props;

  const { frontmatter } = data.file.childMdx;
  const {
    link,
    title,
    content,
    pub_date,
    excerpt,
    hero_image,
    html_embed_code,
  } = frontmatter;

  return (
    <WithHeroLayout
      pageTitle={title}
      bgImgSrc={hero_image}
      path={location?.pathname}
    >
      {excerpt && (
        <Section>
          <SectionDescription>{excerpt}</SectionDescription>
        </Section>
      )}
      <MDX>{content}</MDX>
      {html_embed_code && (
        <Section>
          <div dangerouslySetInnerHTML={{ __html: html_embed_code }} />
        </Section>
      )}
    </WithHeroLayout>
  );
};

export const query = graphql`
  query($absolutePath: String!) {
    file(absolutePath: { eq: $absolutePath }) {
      childMdx {
        body
        frontmatter {
          link
          title
          content
          excerpt
          pub_date
          hero_image
        }
      }
    }
  }
`;

export default PageTemplate;
